import React from 'react';
import * as Styled from './commonAccordionStyles';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { useContentfulAssets } from '../../../hooks/useContentfulAssets';
import { FormTypes, sectionList } from '../../../constants/queryFragments';
import isExternalUrl, {
  addTrailingSlash,
  isExternalUrlHref,
} from '../../../utils';

const CommonAccordion = ({ sectionData }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <Styled.ListContainer>{children}</Styled.ListContainer>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <Styled.ListItem>{children}</Styled.ListItem>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Styled.Header3>{children}</Styled.Header3>
      ),
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);

        if (asset) {
          return (
            <Styled.LinkStyle
              href={
                isExternalUrlHref(asset.node?.file?.uri)
                  ? asset.node?.file?.uri
                  : addTrailingSlash(
                      process.env.GATSBY_DOMAIN_URL + asset.node?.file?.uri
                    )
              }
              target={'_blank'}
            >
              {children}
            </Styled.LinkStyle>
          );
        }
      },
      [BLOCKS.HR]: () => <Styled.HorizontalBreak />,
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };

  return (
    <>
      <Styled.AccordionContainer>
        <Styled.AccordionItem>
          <Styled.AccordionHeader>{sectionData?.title}</Styled.AccordionHeader>
          <Styled.AccordionBody>
            {sectionData?.contentDetails &&
              documentToReactComponents(
                JSON.parse(sectionData?.contentDetails?.raw),
                optionsMainStyle
              )}
          </Styled.AccordionBody>
        </Styled.AccordionItem>
      </Styled.AccordionContainer>
    </>
  );
};

export default CommonAccordion;
